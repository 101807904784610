import type { StorageItem } from './types';
import type { CloudStorageProviderItem } from '../../../../clients/cloud-storage/types';
import type { CloudStoragePassthroughItem, CloudStorageProjectItem } from '@mtb/cloud-storage/types';
import { CloudStorageClient, LoggerClient } from '../../../../clients';

class CloudStorageItem implements StorageItem {
  #item: CloudStorageProviderItem;

  #logger = LoggerClient.createNamedLogger(`CloudStorageItem:${this.name}`);

  get name() {
    return CloudStorageClient.getNameParts(this.#item?.name).displayName;
  }

  get extension() {
    return CloudStorageClient.getNameParts(this.#item?.name).extension;
  }

  constructor(item: CloudStorageProviderItem) {
    try {
      if (!item) {
        throw new Error('Invalid item.');
      }
      this.#item = { ...item };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Check if the name of the item matches the given name.
   * @param name - The name to check against.
   * @returns - Whether the name matches.
   */
  doesNameMatch(name: string) {
    return `${this.name}.${this.extension}` === name;
  }

  /**
   * Verify the item before opening it.
   * @returns - Whether the item is able to be opened.
   */
  verifyBeforeOpen() {
    const canOpen = CloudStorageClient.verifyBeforeOpen(this.#item);
    return canOpen;
  }

  /**
   * Duplicate the item.
   * @returns - A promise that resolves when the item has been duplicated.
   */
  async duplicate() {
    try {
      const duplicatedItem = await await CloudStorageClient.duplicateItem(this.#item);
      if (!duplicatedItem) {
        throw new Error('Failed to duplicate item.');
      }
      this.#item = { ...duplicatedItem };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Rename the item.
   * @param name - The new name of the item.
   * @returns - A promise that resolves when the item has been renamed.
   */
  async rename(name: string) {
    try {
      const renamedItem = await CloudStorageClient.renameItem(this.#item, name);
      if (!renamedItem) {
        throw new Error('Failed to rename item.');
      }
      this.#item = {
        ...this.#item,
        name: renamedItem,
      };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Create a passthrough item from the item.
   * @returns - A promise that resolves with the passthrough item.
   */
  async createPassthroughItem(): Promise<CloudStoragePassthroughItem> {
    try {
      const passthroughItem = await CloudStorageClient.openPassthroughItem(this.#item);
      if (!passthroughItem || typeof passthroughItem === 'boolean') {
        throw new Error('Failed to create passthrough item.');
      }
      return passthroughItem;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Create a project from the item.
   * @param name - The name of the project.
   * @returns - A promise that resolves with the project.
   */
  async createProject(name: string): Promise<CloudStorageProjectItem> {
    try {
      const projectItem = { ...this.#item, ...(name && { name }) };
      const project = await CloudStorageClient.openProject(projectItem);
      if (!project || typeof project === 'boolean') {
        throw new Error('Failed to create project.');
      }
      return project;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }
}

export default CloudStorageItem;
