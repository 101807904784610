import type { StorageItem } from './storage-item/types';
import type { CreateOptions, CreatePlaneOptions, CreateResult, OpenOptions, OpenResult } from './types';
import type { ModuleConfig, ModuleConfigKey } from '../../modules/types';
import type { PlaneId } from '../../store/reducers/planes';
import type { NestedPlaneState, Plane } from '../../types';
import { deepMerge } from '@mtb/utilities';
import { CloudStorageClient, DialogsClient, I18nClient, ModuleClient } from '../../../clients';
import BrowserHistoryClient from '../../../clients/browser-history';
import Logger from '../../../clients/logger';
import config from '../../../config';
import { ROOT_MODULE_KEY } from '../../constants';
import MODULES from '../../modules';
import { platformStore } from '../../store';
import { PLANE_STATUS_TYPES } from '../../store/reducers/planes';
import StorageItemClient from './storage-item';
import { verifyAddPassthroughToPlane, verifyBeforeCreate, verifyBeforeOpen, verifyMaxPlanes } from './verify';

class PlaneService {
  #logger = Logger.createNamedLogger('PlaneService');

  /**
   * Gets the formatted parts of the plane name.
   * @param module - The name to format.
   * @param name - The name to get the parts from.
   * @returns The parts of the plane name.
   */
  #getPlaneNameParts(module: ModuleConfig, name?: string) {
    // @ts-expect-error - Ignore until we fix the Module Config types.
    const defaultName = I18nClient.t(module.storage.defaultProjectName);
    const nameParts = CloudStorageClient.getNameParts(name || defaultName);
    if (!nameParts.displayName) {
      throw new Error('Failed to get plane name parts.');
    }
    return {
      ...nameParts,
      extension: Boolean(nameParts.extension)
        ? nameParts.extension
        : module.storage?.defaultExtension?.startsWith('.')
          ? module.storage?.defaultExtension.slice(1)
          : module.storage?.defaultExtension,
    };
  }

  /**
   * Creates a new plane with the given options.
   * @param module - The module to create the plane with.
   * @param options - The options to create the plane with.
   * @returns The created plane.
   */
  #createPlane(module: ModuleConfig, options: CreatePlaneOptions) {
    try {
      const { displayName, extension } = this.#getPlaneNameParts(module, options.name);
      const defaultState = module.storage?.defaultPlaneState || {};
      const optionsState = options.state || {};
      const state = deepMerge(defaultState, optionsState) as NestedPlaneState;
      const { payload: plane } = platformStore.actions.createPlane({
        module   : module.key,
        icon     : module.icon,
        name     : displayName,
        extension: `.${extension}`,
        state,
      });
      if (!options.preventNavigation) {
        platformStore.actions.setCurrentLayout({
          currentModuleKey: plane.module,
          currentPlaneId  : plane.id,
        });
        BrowserHistoryClient.updateBrowserHistory(
          {
            currentModuleKey: plane.module,
            currentPlaneId  : plane.id,
          },
          { replace: false },
        );
      }
      return plane;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  async #createProjectForPlane(plane: Plane, storageItem: StorageItem) {
    try {
      const project = await storageItem.createProject(`${plane.name}${plane.extension}`);
      platformStore.actions.updatePlane(plane.id, {
        // Ensure we update the name in case it was changed during project creation.
        name          : project.displayName,
        // Set the project ID to the plane to associate the plane with the cloud storage project.
        cloudStorageId: project.projectId,
        // Put the plane in the default loading state to signify that the remote module
        // of the plane can start the process of loading.
        status        : PLANE_STATUS_TYPES.LOADING,
      });
    } catch (error) {
      const planeError = error instanceof Error ? error : new Error('Failed to create project for plane.');
      platformStore.actions.setPlaneError(plane.id, planeError);
      this.#logger.error(planeError);
    }
  }

  /**
   * Adds the passthrough item to the given plane either by updating the plane's state,
   * or by opening the passthrough item if the plane is currently open.
   * @param plane - The plane to add the passthrough item to.
   * @param options - The options to add the passthrough item with.
   * @param options.storageItem - The storage item to add to the plane.
   * @param options.file - The file to add to the plane.
   * @returns The plane with the passthrough item added.
   */
  async #addPassthroughToPlane(plane: Plane, storageItem: StorageItem): Promise<Plane> {
    verifyAddPassthroughToPlane(plane, storageItem);

    const passthroughItem = await storageItem.createPassthroughItem();
    // If a plane isn't currently open or the current plane id is different the given plane id,
    // we need to add the passthrough item to the target plane's state. This will allow
    // the plane to load the passthrough item the next time the plane is opened.
    const currentPlane = this.getCurrentPlane();
    const isCurrentPlaneActive = currentPlane && currentPlane?.id === plane.id;
    if (!isCurrentPlaneActive) {
      const planeState = deepMerge(plane.state, { passthroughItem }) as NestedPlaneState;
      platformStore.actions.updatePlane(plane.id, { state: planeState });
      return plane;
    }

    await ModuleClient.Events.open(plane, passthroughItem);
    return plane;
  }

  getCurrentPlane() {
    return platformStore.selectors.currentPlane(platformStore.getState());
  }

  /**
   * Gets the plane with the given planeId from the store.
   */
  getPlaneById(planeId?: PlaneId): Plane | undefined {
    return planeId ? platformStore.selectors.plane(platformStore.getState(), planeId) : undefined;
  }

  /**
   * Gets the planes from the store sorted by created date in the given order.
   * @param order - The sorting order, either 'asc' (ascending) or 'desc' (descending). Default is 'desc'.
   */
  getPlanesByCreatedDate(order: 'asc' | 'desc' = 'desc'): Plane[] {
    return platformStore.selectors.planesByCreatedDate(platformStore.getState(), order);
  }

  /**
   * Updates the plane with the given planeId with the given plane properties.
   * @param planeId
   * @param planeProps
   */
  update(planeId: PlaneId, planeProps: Partial<Plane>) {
    platformStore.actions.updatePlane(planeId, planeProps);
  }

  /**
   * Determines whether we should confirm closing the plane with the given planeId.
   * @param planeId - The planeId to verify.
   */
  shouldConfirmClose(planeId: PlaneId) {
    const plane = platformStore.selectors.plane(platformStore.getState(), planeId);
    const { isHealthy: isPlaneHealthy } = platformStore.selectors.planeHealth(platformStore.getState(), planeId);
    const isUnsavedProject = CloudStorageClient.isUnsavedProject(plane?.cloudStorageId);
    return plane?.dirty || (isUnsavedProject && isPlaneHealthy);
  }

  /**
   * Closes the plane with the given planeId.
   * @param planeId - The planeId to close.
   * @returns Whether the plane was closed successfully.
   */
  async closePlane(planeId: PlaneId): Promise<void> {
    const canClose = this.shouldConfirmClose(planeId) ? await DialogsClient.showConfirmCloseDialog() : true;
    if (!canClose) {
      return;
    }

    const currentPlaneId = platformStore.selectors.currentPlaneId(platformStore.getState());
    const currentModuleKey = platformStore.selectors.currentModuleKey(platformStore.getState());
    if (planeId === currentPlaneId) {
      const nextPlane = this.getPlanesByCreatedDate().find(plane => plane.id !== planeId);
      if (nextPlane) {
        platformStore.actions.setPlaneLoading(nextPlane.id, true);
        platformStore.actions.setCurrentLayout({
          currentModuleKey: nextPlane.module,
          currentPlaneId  : nextPlane.id,
        });
        BrowserHistoryClient.updateBrowserHistory({
          currentModuleKey: nextPlane.module,
          currentPlaneId  : nextPlane.id,
        });
      } else {
        // TODO: While we're transitioning to the new landing page changes we don't want to
        // break the existing routing logic when closing planes Once we get everything working we can set this
        // to always go to the "ROOT_MODULE_KEY".
        const nextModuleKey = config.feature_flag_ui_v2 ? ROOT_MODULE_KEY : currentModuleKey || ROOT_MODULE_KEY;
        platformStore.actions.setCurrentLayout({
          currentModuleKey: nextModuleKey,
          currentPlaneId  : undefined,
        });
        BrowserHistoryClient.updateBrowserHistory({
          currentModuleKey: nextModuleKey,
          currentPlaneId  : undefined,
        });
      }
    }
    platformStore.actions.closePlane(planeId);
  }

  /**
   * Verifies that the maximum number of planes has not been reached.
   * @deprecated - Remove once the client isn't using this anymore.
   */
  verifyMaxPlanes() {
    verifyMaxPlanes();
  }

  /**
   * Creates a new plane with the given options.
   * TODO: Add support to show a dialog when the process of opening the analysis takes
   * longer than expected threshold.
   * @param module - The module to create the plane with.
   * @param options - The options to create the plane with.
   * @returns - The created plane.
   */
  async invokeCreate(module: ModuleConfig, options: CreateOptions): CreateResult {
    try {
      verifyMaxPlanes();
      verifyBeforeCreate(module, options);

      const storageItem = StorageItemClient.createItem(options);
      const plane = this.#createPlane(module, {
        name : options.name || storageItem.name,
        state: options.state || {},
      });
      await options.onBeforeCreate?.(storageItem);
      await this.#createProjectForPlane(plane, storageItem);
      return plane.id;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Gets or creates the plane with the given module and options.
   * @param module
   * @param options
   * @returns
   */
  async #getOrCreatePlane(module: ModuleConfig, options: OpenOptions): Promise<Plane> {
    try {
      const plane = this.getPlaneById(options.planeId);
      if (plane) {
        return plane;
      }
      const createdPlaneId = await this.invokeCreate(module, {
        state            : options.state,
        preventNavigation: options.preventNavigation,
      });
      const createdPlane = this.getPlaneById(createdPlaneId);
      if (!createdPlane) {
        throw new Error('Failed to get or create plane.');
      }
      return createdPlane;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Opens a plane with the given options.
   * TODO: Add support to show a dialog when the process of opening the analysis takes
   * longer than expected threshold.
   * @param module - The module to open the plane with.
   * @param options - The options to open the plane with.
   * @returns The opened plane.
   */
  async invokeOpen(module: ModuleConfig, options: OpenOptions): OpenResult {
    try {
      verifyBeforeOpen(module, options);

      const plane = await this.#getOrCreatePlane(module, options);
      const storageItem = StorageItemClient.createItem(options);
      await options.onBeforeOpen?.(storageItem);
      await this.#addPassthroughToPlane(plane, storageItem);

      return plane.id;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Creates a new MSSO analysis plane with the given options.
   * TODO: Add support to show a dialog when the process of opening the analysis takes
   * longer than expected threshold.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createAnalysis(options: CreateOptions = {}): CreateResult {
    // Mark an analysis as new if it's not being created with a storage item or file.
    const initialState = { isNew: Boolean(!options.storageItem && !options.file) };
    const createOptions = deepMerge({ state: initialState }, options);
    return await this.invokeCreate(MODULES.MSSO, createOptions);
  }

  /**
   * Creates a new Reporting Tool plane with the given options.
   * @param options - The options to create the report with.
   * @returns The created report plane.
   */
  async createReportTool(options: CreateOptions = {}): CreateResult {
    // Mark an analysis as new if it's not being created with a storage item or file.
    const initialState = { isNew: Boolean(!options.storageItem && !options.file) };
    const createOptions = deepMerge({ state: initialState }, options);
    return await this.invokeCreate(MODULES.REPORTING_TOOL, createOptions);
  }

  /**
   * Opens an analysis plane with the given options.
   * @param options - The options to open the analysis with.
   * @returns The opened analysis plane.
   */
  async openAnalysis(options: OpenOptions): OpenResult {
    return await this.invokeOpen(MODULES.MSSO, options);
  }

  /**
   * Creates a new workspace plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the workspace with.
   * @returns {CreateResult} The created workspace plane.
   */
  async createWorkspace(options: CreateOptions = {}): CreateResult {
    const module = MODULES.WSO;
    const defaultExtension = module.storage?.defaultExtension || '';
    options.onBeforeCreate = async (storageItem: StorageItem) => {
      if (storageItem.extension.toLowerCase() === 'qcpx') {
        await storageItem.duplicate();
        await storageItem.rename(`${storageItem.name}${defaultExtension}`);
      }
    };
    return await this.invokeCreate(module, options);
  }

  /**
   * Opens a workspace plane with the given options.
   * @param {OpenOptions} options - The options to open the workspace with.
   * @returns {OpenResult} The opened workspace plane.
   */
  async openWorkspace(options: OpenOptions): OpenResult {
    return await this.invokeOpen(MODULES.WSO, options);
  }

  /**
   * Creates a new brainstorm plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the brainstorm with.
   * @returns {CreateResult} The created brainstorm plane.
   */
  async createBrainstorm(options: CreateOptions = {}): CreateResult {
    return await this.invokeCreate(MODULES.BRAINSTORM, options);
  }

  /**
   * Opens a brainstorm plane with the given options.
   * @param {OpenOptions} options - The options to open the brainstorm with.
   * @returns {OpenResult} The opened brainstorm plane.
   */
  async openBrainstorm(options: OpenOptions): OpenResult {
    return await this.invokeOpen(MODULES.BRAINSTORM, options);
  }

  /**
   * Creates a new data plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the data plane with.
   * @returns {CreateResult} The created data plane.
   */
  async createDataPrep(options: CreateOptions = {}): CreateResult {
    return await this.invokeCreate(MODULES.DATACENTER, options);
  }

  /**
   * Opens a data plane with the given options.
   * @param {OpenOptions} options - The options to open the data plane with.
   * @returns {OpenResult} The opened data plane.
   */
  async openDataPrep(options: OpenOptions): OpenResult {
    return await this.invokeOpen(MODULES.DATACENTER, options);
  }

  /**
   * Creates a new report plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the report plane with.
   * @returns {CreateResult} The created report plane.
   */
  async createReport(options: CreateOptions = {}): CreateResult {
    return await this.invokeCreate(MODULES.REPORTING_TOOL, options);
  }

  /**
   * Opens a report plane with the given options.
   * @param {OpenOptions} options - The options to open the report plane with.
   * @returns {OpenResult} The opened report plane.
   */
  async openReport(options: OpenOptions): OpenResult {
    return await this.invokeOpen(MODULES.REPORTING_TOOL, options);
  }

  /**
   * Gets the available plane actions for the given module key.
   * @param moduleKey - The key of the module.
   * @returns The available plane actions for the given module key.
   */
  getActionsByModuleKey(moduleKey: ModuleConfigKey) {
    try {
      let createAction;
      let openAction;
      switch (moduleKey) {
        case MODULES.DATACENTER.key:
          createAction = this.createDataPrep.bind(this);
          openAction = this.openDataPrep.bind(this);
          break;
        case MODULES.WSO.key:
          createAction = this.createWorkspace.bind(this);
          openAction = this.openWorkspace.bind(this);
          break;
        case MODULES.BRAINSTORM.key:
          createAction = this.createBrainstorm.bind(this);
          openAction = this.openBrainstorm.bind(this);
          break;
        case MODULES.REPORTING_TOOL.key:
          createAction = this.createReport.bind(this);
          openAction = this.openReport.bind(this);
          break;
        case MODULES.MSSO.key:
          createAction = this.createAnalysis.bind(this);
          openAction = this.openAnalysis.bind(this);
          break;
        default:
          throw new Error(`Unsupported module key: ${moduleKey}`);
      }
      return {
        create: createAction,
        open  : openAction,
      };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }
}

export default PlaneService;
